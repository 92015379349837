var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import styles from './Breadcrumbs.style';
import Anchor from '../Anchor';
import { useRouter } from 'next/router';
import ArrowRight from '../../../static/images/icons/ArrowRight';
var getBreadcrumbs = function getBreadcrumbs(_ref) {
  var _query$slug;
  var data = _ref.data,
    query = _ref.query;
  if (data.__typename === 'Blog') {
    var _data$blogCategory;
    return __jsx(React.Fragment, null, __jsx("li", {
      className: "nva-breadcrumbs__item",
      "aria-hidden": "true"
    }, __jsx(ArrowRight, {
      "aria-hidden": "true",
      className: "nva-breadcrumbs__arrow"
    })), __jsx("li", {
      className: "nva-breadcrumbs__item u-button"
    }, "".concat(data === null || data === void 0 ? void 0 : (_data$blogCategory = data.blogCategory) === null || _data$blogCategory === void 0 ? void 0 : _data$blogCategory.categoryName)));
  }

  // Removes the first portion as that is being handled by the getBaseUrl
  var breadcrumbParts = query === null || query === void 0 ? void 0 : (_query$slug = query.slug) === null || _query$slug === void 0 ? void 0 : _query$slug.filter(function (slug) {
    return slug !== (query === null || query === void 0 ? void 0 : query.slug[0]);
  });
  return breadcrumbParts.map(function (breadcrumbPart) {
    return __jsx(React.Fragment, null, __jsx("li", {
      className: "nva-breadcrumbs__item",
      "aria-hidden": "true"
    }, __jsx(ArrowRight, {
      "aria-hidden": "true",
      className: "nva-breadcrumbs__arrow"
    })), __jsx("li", {
      className: "nva-breadcrumbs__item u-button"
    }, breadcrumbPart.replaceAll('-', ' ')));
  });
};
var Breadcrumbs = function Breadcrumbs(_ref2) {
  var className = _ref2.className,
    baseRoute = _ref2.baseRoute,
    data = _ref2.data;
  var _useRouter = useRouter(),
    query = _useRouter.query;
  var getBaseUrl = function getBaseUrl() {
    return "/".concat(query === null || query === void 0 ? void 0 : query.slug[0]);
  };
  return __jsx("nav", {
    "aria-label": "Breadcrumbs",
    className: cn('nva-breadcrumbs', className)
  }, __jsx("div", {
    className: "l-grid"
  }, __jsx("div", {
    className: "l-col-full"
  }, baseRoute ? __jsx("ol", null, __jsx("li", {
    className: "nva-breadcrumbs__item u-button"
  }, __jsx(Anchor, {
    removeDefaultStyles: true,
    to: getBaseUrl()
  }, baseRoute)), getBreadcrumbs({
    query: query,
    data: data
  })) : __jsx("button", {
    className: "nva-breadcrumbs__item nva-breadcrumbs__item--go-back u-button",
    onClick: function onClick() {
      window.history.back();
    }
  }, __jsx(ArrowRight, {
    style: {
      rotate: '180deg'
    },
    "aria-hidden": "true",
    className: "nva-breadcrumbs__arrow"
  }), "Go Back"))));
};
export default styled(Breadcrumbs).withConfig({
  componentId: "sc-1hsis82-0"
})(["", ";"], styles);